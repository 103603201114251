import React, {useState} from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { useQuery } from 'react-query';
import {
    useQueryParam,
    ArrayParam,
    UrlUpdateType
} from 'use-query-params';

import { getOrgan, getOrganIds, getOrganSystem, getOrganSystemIds, getTissue, getTissueIds } from '../api';

interface DisclosureCompProps {
  filters: string[]; // Array of filter types
}
interface FilterProps {
  id: number;
  type: string;
  fetchHandler: (id: number) => Promise<any>;
  filterHandler: (checked: boolean) => void;
}

export const Filter = (props: FilterProps) => {
    const {isLoading, isError, data, error} = useQuery([props.type, props.id], () => props.fetchHandler(props.id));
    const [checked, setChecked] = useState(false);

    if (isLoading) {
        return (
            <div className="flex gap-2">
                <div className="w-3 h-3 bg-indigo-800 shadow animate-pulse"></div>
                <div className="w-2/3 h-3 bg-indigo-800 shadow animate-pulse"></div>
            </div>
        );
    }

    if (isError) {
        if (error instanceof Error) {
            return <span>Fehler: {error.message}</span>;
        } else {
            return <span>Unbekannter Fehler ist aufgetreten</span>;
        }
    }

    const handleChange = () => {
        setChecked(!checked);
        props.filterHandler(checked);
    }

    return (
        <label className="flex items-center gap-2 hover:cursor-pointer" htmlFor={`filter-${props.type}-${props.id}`}>
            <input className="filter-input !bg-indigo-700 dark:!bg-indigo-200 bg-(image:)"
                   id={`filter-${props.type}-${props.id}`}
                   name={`filter-${props.type}-${props.id}`}
                   type="checkbox"
                   onChange={handleChange}
                   checked={checked}/>
            {data?.title}
        </label>
    )
}


const filterTitles: { [key: string]: string } = {
    organSystems: 'Organsysteme',
    tissues: 'Gewebe',
    organs: 'Organe',
    findings: 'Befund',
    coloration: 'Färbung',
    bonus: 'Zusatzmaterial',
};

export const DisclosureComp: React.FC<DisclosureCompProps> = ({filters}) => {
    const organIds = useQuery(['organs'], getOrganIds);
    const organSystemIds = useQuery(['organSystems'], getOrganSystemIds);
    const tissueIds = useQuery(['tissues'], getTissueIds);

    const [organSystemIdsParam, setOrganSystemIdsParam] =
        useQueryParam('organSystemIds', ArrayParam);
    const [tissueIdsParam, setTissueIdsParam] =
        useQueryParam('tissueIds', ArrayParam);
    const [organIdsParam, setOrganIdsParam] =
        useQueryParam('organIds', ArrayParam);


    const filterHandler = (
        param: (string | null)[] | null | undefined,
        id: number,
        paramHandler: (newValue: (string | null)[] | null | undefined, updateType?: UrlUpdateType) => void
    ) => {
        if (param?.includes(id.toString())) {
            paramHandler(param.filter(p => p !== id.toString()), 'replace');
        } else {
            paramHandler([...(param || []), id.toString()], 'replace');
        }
    }



    const renderFilterSection = (filter: string) => {
    switch (filter) {
      case 'organSystems':
        return (
            <>
              {organSystemIds.isLoading && <span>Organsysteme laden…</span>}
              {organSystemIds.isSuccess &&
                  organSystemIds.data.map((o, i) => (
                      <div key={i} className="mb-1">
                        <Filter
                            id={o.id}
                            type="organSystem"
                            fetchHandler={getOrganSystem}
                            filterHandler={() => filterHandler(organSystemIdsParam, o.id, setOrganSystemIdsParam)}
                        />
                      </div>
                  ))}
            </>
        );
      case 'tissues':
        return (
            <>
              {tissueIds.isLoading && <span>Gewebe laden…</span>}
              {tissueIds.isSuccess &&
                  tissueIds.data.map((t, i) => (
                      <div key={i} className="mb-1">
                        <Filter
                            id={t.id}
                            type="tissue"
                            fetchHandler={getTissue}
                            filterHandler={() => filterHandler(tissueIdsParam, t.id, setTissueIdsParam)}
                        />
                      </div>
                  ))}
            </>
        );
      case 'organs':
        return (
            <>
              {organIds.isLoading && <span>Organe laden…</span>}
              {organIds.isSuccess &&
                  organIds.data.map((o, i) => (
                      <div key={i} className="mb-1">
                        <Filter
                            id={o.id}
                            type="organ"
                            fetchHandler={getOrgan}
                            filterHandler={() => filterHandler(organIdsParam, o.id, setOrganIdsParam)}
                        />
                      </div>
                  ))}
            </>
        );
      case 'findings':
        return (
            <>
              {['physiologic', 'pathologic'].map((finding, i) => (
                  <div key={i} className="mb-1">
                    <label className="flex items-center gap-2 hover:cursor-pointer" htmlFor={`filter-finding-${finding}`}>
                      <input
                          className="filter-input"
                          id={`filter-finding-${finding}`}
                          name={`filter-finding`}
                          type="radio"
                      />
                      {finding}
                    </label>
                  </div>
              ))}
            </>
        );
      case 'coloration':
        return (
            <>
              {['Azan', 'Eisenhämatoxylin', 'Elastika-Färbung', 'van Gieson', 'Goldner', 'HE', 'Masson'].map((coloration, i) => (
                  <div key={i} className="mb-1">
                    <label className="flex items-center gap-2 hover:cursor-pointer" htmlFor={`filter-coloration-${coloration}`}>
                      <input
                          className="filter-input"
                          id={`filter-coloration-${coloration}`}
                          name={`filter-coloration`}
                          type="radio"
                      />
                      {coloration}
                    </label>
                  </div>
              ))}
            </>
        );
      case 'bonus':
        return (
            <>
              <div className="mb-1">
                <label className="flex items-center gap-2 hover:cursor-pointer" htmlFor="filter-bonus-quiz">
                  <input
                      className="filter-input"
                      id="filter-bonus-quiz"
                      name="filter-bonus-quiz"
                      type="checkbox"
                  />
                  Quiz
                </label>
              </div>
              <div className="mb-1">
                <label className="flex items-center gap-2 hover:cursor-pointer" htmlFor="filter-bonus-video">
                  <input
                      className="filter-input"
                      id="filter-bonus-video"
                      name="filter-bonus-video"
                      type="checkbox"
                  />
                  Video
                </label>
              </div>
            </>
        );
      default:
        return null;
    }
  };

  return (
      <div className="flex flex-col gap-2">
        {filters.map((filter) => (
            <Disclosure key={filter}>
              {({ open }) => (
                  <>
                    <Disclosure.Button className="group flex w-full items-center justify-between rounded-md p-2 text-left text-sm font-semibold text-gray-700 dark:text-white hover:bg-gray-50 dark:hover:bg-indigo-900">
                      <span>{filterTitles[filter]}</span>
                      <ChevronRightIcon
                          className={`${open ? 'rotate-90' : 'rotate-0'} h-5 w-5 transform transition-transform`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="mt-1 pl-4">
                      {renderFilterSection(filter)}
                    </Disclosure.Panel>
                  </>
              )}
            </Disclosure>
        ))}
      </div>
  );
};